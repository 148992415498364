
// HEADER //

header {
  position: relative;
  z-index: 3;
}

.header-wrapper {
  position: fixed;
  background-color: #f8f8f8;
  float: left;
  width: 100%;
}

.header-logo {
  padding-top: 20px;
  padding-top: 20px;
  padding-bottom: 35px;
  position: relative;
  float: left;

}

 .desktop-menu {
   display: none;
   @include breakpoint(large) {
     padding-top: 50px;
     display: inline-block;
     position: relative;
     background-color: #f8f8f8;
   }
  > li {
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 15px;
    z-index: 2;
    position: relative;
    transition: all 0.2s ease-in-out;
    height: 38px;
    > a {
      color: #777a7a;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      -webkit-transform: skew(20deg);
      -moz-transform: skew(20deg);
      -o-transform: skew(20deg);
      background-color: #257d73;
      > a {
        color: white;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -o-transform: skew(-20deg);
      }
    }
    @include breakpoint(large only) {
      font-size: 12px;
    }
  }
}


 .nav-background {
   height: 38px;
   background: #dbe9e8;
   position: absolute;
   z-index: 1;
   width: 150px;
   -webkit-transform: skew(20deg);
   -moz-transform: skew(20deg);
   -o-transform: skew(20deg);
   @include breakpoint(large only) {
     width: 623px;
   }
   @include breakpoint(xlarge) {
     width: 705px;
   }
 }


 .burger-nav {
   display: inline-block;
   float: right;
   position: absolute;
   top: 25px;
   right: 15px;
   margin-right: 12px;
   @include breakpoint(medium) {
     top: 51px;
     right: 26px;
   }
   @include breakpoint(large) {
     display: none;
   }
   a {
     font-size: 36px;
     color: #b5aa9d;
   }
 }

 .mobile-menu {
   display: none;
   position: absolute;
   float: right;
   width: 49%;
   background-color: #f8f8f8;
   @include breakpoint(medium) {
     width: 32%;
   }
   @include breakpoint (large) {
     display: none !important;
   }
   li {
     font-family: 'Montserrat', sans-serif;
     text-align: center;
     letter-spacing: 1px;
     background-color: #f8f8f8;
     &:hover {
       background-color: #257d73;
     }
   }
   a {
     color: #257d73;
     &:hover {
       color: #f8f8f8;
     }
   }
 }


// MAIN CONTENT //

.home-hero-image {
  position: relative;
  z-index: 2;
  margin-top: 97px;
  @include breakpoint(medium) {
    margin-top: 117px;
  }
  @include breakpoint(large) {
    margin-top: 121px;
  }
  @include breakpoint(xlarge) {
    margin-top: 127px;
  }
  @include breakpoint(xxlarge) {
    margin-top: 113px;
  }
}

.home-hero-text {
  background-color: #257d73;
  color: white;
  z-index: 1;
  position: relative;
  float: left;
  padding-top: 165px;
  margin-top: -176px;
  @include breakpoint (xxlarge only) {
    width: 80%;
    margin-top: -335px;
    left: 152px;
  }
  @include breakpoint (xlarge only) {
    width: 80%;
    margin-top: -322px;
    left: 131px;
  }
  @include breakpoint (large only) {
    width: 80%;
    margin-top: -283px;
    left: 105px;
  }
  @include breakpoint (medium only) {
    margin-top: -238px;
    padding-right: 35px;
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin-left: 15px;
    @include breakpoint (medium) {
      margin-left: 84px;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0px;
    padding-bottom: 40px;
    margin-left: 15px;
    @include breakpoint (xxlarge only) {
      margin-left: 84px;
      margin-right: 245px;
    }
    @include breakpoint (xlarge only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (large only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (medium only) {
      margin-left: 84px;
    }
  }
}

.upper-about-us {
  text-align: center;
  color: #777a7a;
  position: relative;
  float: left;
  width: 100%;
  padding-top: 75px;
  @include breakpoint(medium) {
    padding-top: 120px;
  }
  h3 {
    margin-bottom: -7px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
  }
  h2 {
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
  }
  p {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    @include breakpoint (medium) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.lower-about-us {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  @include breakpoint(medium) {
    padding-top: 80px;
  }
  h1 {
    color: #dbe9e8;
    font-family: 'Montserrat', sans-serif;
    font-size: 114px;
    position: relative;
    z-index: 1;
    @include breakpoint (medium only) {
      font-size: 90px;
    }
  }
  p {
    color: #777a7a;
    font-size: 13px;
    z-index: 2;
    margin-top: -82px;
    position: relative;
    padding-left: 12px;
    padding-right: 28px;
    margin-left: 28px;
    @include breakpoint (medium only) {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
    }
    @include breakpoint (large) {
      margin-left: 70px;
    }
  }
  a {
    color: #257d73;
  }
}

// RETAIL SITES SECTION //

.mobile-retail-sites {
  display: inline-block;
  @include breakpoint(medium) {
    display: none;
  }
}

.desktop-retail-sites {
  display: none;
  @include breakpoint(medium) {
    display: inline-block;
  }
}

.retail-sites-wrapper {
  transform: skewY(-4deg);
  position: relative;
  float: left;
  background-color: #257d73;
  height: 740px;
  width: 100%;
  z-index: 1;
  margin-bottom: -160px;
  @include breakpoint(medium only) {
    margin-top: 66px;
    margin-bottom: -280px;
  }
  @include breakpoint(large) {
    width: 80%;
    left: 105px;
    margin-top: 77px;
    height: 657px;
  }
  @include breakpoint(xlarge) {
    left: 152px;
    height: 610px;
    margin-top: 95px;
  }
}

.retail-sites {
  position: relative;
  background-color: #257d73;
  color: white;
  text-align: center;
  padding-top: 40px;
  margin-top: 35px;
  transform: skewY(4deg);
  margin-left: 10px;
  margin-right: 10px;
  @include breakpoint(medium) {
    padding-right: 35px;
    text-align: left;
    float: left;
    margin-top: 50px;
    margin-left: 80px;
  }
  @include breakpoint (large) {
    width: 80%;
    left: 152px;
    margin-left: 0px;
    margin-top: 60px;
  }
  h3 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 16px;
    @include breakpoint (medium) {
      font-size: 2.5rem;
      float: right;
    }
  }
  p {
    float: right;
  }
}

.retail-section-wedding, .retail-section-florist, .retail-section-party, .retail-section-garden, .retail-section-tld {
  z-index: 2;
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  text-align: center;
}

.retail-section-wedding-text, .retail-section-florist-text, .retail-section-garden-text, .retail-section-tld-text {
  padding-top: 40px;
  padding-bottom: 40px;
  @include breakpoint(medium only) {
    padding-top: 7px;
    padding-bottom: 0px;
  }
  @include breakpoint(large only) {
    padding-top: 34px;
    padding-bottom: 0;
  }
  @include breakpoint(xlarge) {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  p {
    color: #777a7a;
    padding-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    @include breakpoint(medium only) {
      font-size: 13px;
      padding-top: 5px;
    }
    @include breakpoint(large only) {
      font-size: 13px;
    }
    @include breakpoint(xlarge) {
      margin-left: 10px;
      margin-right: 10px;
    }
    @include breakpoint(xxlarge) {
      padding-top: 40px;
    }
  }
  a {
    color: #257d73;
    letter-spacing: 2px;
    font-weight: normal;
    transition: font-weight 0.1s ease-in-out;
    @include breakpoint(medium only) {
      font-size: 14px;
    }
    @include breakpoint(xlarge) {
      font-size: 20px;
    }
    &:hover {
      font-weight: bold;
    }
  }
}

.retail-section-party-text {
  padding-top: 30px;
  padding-bottom: 40px;
  @include breakpoint(medium only) {
    padding-top: 2px;
    padding-bottom: 0px;
  }
  @include breakpoint(large only) {
    padding-top: 15px;
    padding-bottom: 0;
  }
  @include breakpoint(xlarge only) {
    padding-top: 50px;
  }
  @include breakpoint(xxlarge) {
    padding-top: 63px;
  }
  p {
    color: #777a7a;
    padding-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    @include breakpoint(medium only) {
      font-size: 13px;
      padding-top: 5px;
    }
    @include breakpoint(large only) {
      font-size: 13px;
    }
    @include breakpoint(xlarge) {
      margin-left: 10px;
      margin-right: 10px;
    }
    @include breakpoint(xxlarge) {
      padding-top: 40px;
    }
  }
  a {
    color: #257d73;
    letter-spacing: 2px;
    font-weight: normal;
    transition: font-weight 0.1s ease-in-out;
    @include breakpoint(medium only) {
      font-size: 14px;
    }
    @include breakpoint(xlarge) {
      font-size: 20px;
    }
    &:hover {
      font-weight: bold;
    }
  }
}

.retail-section-bottom-wrapper {
  transform: skewY(4deg);
  position: relative;
  float: left;
  background-color: #257d73;
  height: 355px;
  width: 100%;
  z-index: 1;
  margin-top: -68px;
  margin-bottom: -20px;
  @include breakpoint(medium only) {
    margin-top: -69px;
    height: 350px;
  }
  @include breakpoint(large) {
    width: 80%;
    left: 105px;
    margin-top: -80px;
    height: 340px;
  }
  @include breakpoint(xlarge) {
    left: 152px;
  }
}

.retail-sites-bottom {
  position: relative;
  background-color: #257d73;
  color: white;
  text-align: center;
  margin-top: 132px;
  transform: skewY(-4deg);
  margin-left: 10px;
  margin-right: 10px;
  @include breakpoint(medium) {
    padding-right: 35px;
    text-align: left;
    float: left;
    margin-top: 145px;
    margin-left: 80px;
  }
  @include breakpoint (large) {
    width: 80%;
    left: 152px;
    margin-left: -67px;
  }
  h3 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 16px;
    @include breakpoint (medium) {
      font-size: 2.5rem;
      float: right;
    }
  }
  p {
    float: right;
  }
}


@media screen and (min-width: 320px) and (max-width: 430px) {
  .mobile-retail-sites {
    .retail-sites-wrapper {
      height: 900px;
    }
  }
  .mobile-trade-sites-section-wrapper {
    .trade-sites-wrapper {
      height: 710px;
    }
  }
}

@media screen and (min-width: 430px) and (max-width: 580px) {
  .mobile-retail-sites {
    .retail-sites-wrapper {
      height: 690px;
    }
  }

  .burger-nav {
    top: 29px;
  }
  .mobile-trade-sites-section-wrapper {
    .trade-sites-wrapper {
      height: 620px;
    }
  }
}

@media screen and (min-width: 580px) and (max-width: 770px) {
  .mobile-retail-sites {
    .retail-sites-wrapper {
      height: 610px;
    }
  }

  .burger-nav {
    top: 40px;
  }
}


//FULFILMENT SECTION //

.fulfilment-section {
    display: none;
    @include breakpoint(medium) {
      display: inline-block;
      position: relative;
      float: left;
      width: 100%;
      text-align: center;
      background-color: #b5aa9d;
      color: white;
      padding-bottom: 30px;
    }
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 16px;
    @include breakpoint (medium) {
      font-size: 2.5rem;
      padding-top: 60px;
    }
  }
  p {
    @include breakpoint(large) {
      padding-left: 150px;
      padding-right: 150px;
    }
    @include breakpoint(xlarge) {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}

.fulfilment-section2 {
  display: inline-block;
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  background-color: #b5aa9d;
  color: white;
  padding-bottom: 30px;
  padding-top: 20px;
  @include breakpoint(medium) {
    display: none;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 16px;
  }
  p {
    padding: 12px;
  }
}

// TRADE SITES SECTION //

.mobile-trade-sites-section-wrapper {
  display: inline-block;
  @include breakpoint(medium) {
    display: none;
  }
}

.desktop-trade-sites-section-wrapper {
  display: none;
  @include breakpoint(medium) {
    display: inline-block;
  }
}

.trade-sites-wrapper {
    transform: skewY(-4deg);
    position: relative;
    float: left;
    background-color: #b5aa9d;
    height: 540px;
    width: 100%;
    z-index: 1;
    margin-top: 50px;
    margin-bottom: -160px;
  @include breakpoint(medium) {
    margin-top: 100px;
  }
  @include breakpoint(medium only) {
    margin-top: 130px;
    margin-bottom: -280px;
    height: 640px;
  }
  @include breakpoint(large) {
    width: 80%;
    left: 105px;
    margin-top: 155px;
    height: 590px;
  }
  @include breakpoint(xlarge) {
    left: 152px;
    height: 555px;
    margin-top: 181px;
  }
}

.trade-sites {
  position: relative;
  background-color: #b5aa9d;
  color: white;
  text-align: center;
  padding-top: 40px;
  margin-top: 35px;
  transform: skewY(4deg);
  margin-left: 10px;
  margin-right: 10px;
  @include breakpoint(medium) {
    padding-right: 35px;
    text-align: left;
    float: left;
    margin-top: 40px;
    margin-left: 80px;
  }
  @include breakpoint (large) {
    width: 80%;
    left: 152px;
    margin-left: 0px;
  }
  h3 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 16px;
    @include breakpoint (medium) {
      font-size: 2.5rem;
      float: right;
    }
  }
  p {
    float: right;
  }
}

.trade-section-uksm, .trade-section-angel, .trade-section-weddingideas, .trade-section-whittingtons {
  z-index: 2;
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  text-align: center;
}

.trade-section-uksm-text, .trade-section-angel-text, .trade-section-weddingideas-text, .trade-section-whittingtons-text {
  padding-top: 30px;
  padding-bottom: 40px;
  @include breakpoint(medium only) {
    padding-top: 5px;
    padding-bottom: 0px;
  }
  @include breakpoint(large only) {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  @include breakpoint(xlarge) {
    padding-top: 20px;
    padding-bottom: 0px;
  }
  @include breakpoint(xxlarge) {
    padding-top: 40px;
    padding-bottom: 0px;
  }
  p {
    color: #777a7a;
    padding-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    @include breakpoint(medium only) {
      font-size: 13px;
      padding-top: 5px;
    }
    @include breakpoint(large) {
      font-size: 13px;
    }
    @include breakpoint(xlarge) {
      margin-left: 70px;
      margin-right: 70px;
      font-size: 15px;
    }
    @include breakpoint(xxlarge) {
      font-size: 17px;
      padding-top: 25px;
      margin-left: 100px;
      margin-right: 100px;
    }
  }
  a {
    color: #257d73;
    letter-spacing: 2px;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
    @include breakpoint(medium only) {
      font-size: 14px;
    }
    @include breakpoint(xlarge) {
      font-size: 20px;
    }
    &:hover {
      font-weight: bold;
    }
  }
}

.trade-section-weddingideas-text, .trade-section-whittingtons-text {
  @include breakpoint(large) {
    padding-top: 40px;
  }
  @include breakpoint(xlarge) {
    padding-top: 38px;
  }
}

.trade-section-bottom-wrapper {
  transform: skewY(4deg);
  position: relative;
  float: left;
  background-color: #b5aa9d;
  height: 200px;
  width: 100%;
  z-index: 1;
  margin-top: -68px;
  margin-bottom: 2px;
  @include breakpoint(medium only) {
    margin-top: -69px;
    height: 250px;
  }
  @include breakpoint(large) {
    width: 80%;
    left: 105px;
    margin-top: -80px;
    height: 270px;
  }
  @include breakpoint(xlarge) {
    left: 152px;
    height: 265px;
  }
}

.trade-sites-bottom {
  position: relative;
  background-color: #b5aa9d;
  color: white;
  text-align: center;
  margin-top: 132px;
  transform: skewY(-4deg);
  margin-left: 10px;
  margin-right: 10px;
  @include breakpoint(medium) {
    padding-right: 35px;
    text-align: left;
    float: left;
    margin-top: 170px;
    margin-left: 80px;
  }
  @include breakpoint (large) {
    width: 80%;
    left: 152px;
    margin-left: -67px;
  }
  h3 {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    letter-spacing: 2px;
    padding-bottom: 16px;
    @include breakpoint (medium) {
      font-size: 2.5rem;
      text-align: left;
      float: left;
    }
  }
  p {
    float: right;
  }
}

@media screen and (min-width: 435px) and (max-width: 730px) {

}

//SUPPLY SECTION //

.supply-hero-text {
  background-color: #7c90a0;
  color: white;
  z-index: 1;
  position: relative;
  float: left;
  padding-top: 40px;
  text-align: center;
  @include breakpoint (xxlarge only) {
    width: 80%;
    left: 152px;
    height: 381px;
    text-align: left;
  }
  @include breakpoint (xlarge only) {
    width: 80%;
    left: 131px;
    height: 350px;
    text-align: left;
  }
  @include breakpoint (large only) {
    width: 80%;
    left: 105px;
    height: 385px;
    text-align: left;
  }
  @include breakpoint (medium only) {
    padding-right: 35px;
    height: 390px;
    text-align: left;
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin-left: 15px;
    @include breakpoint (medium) {
      margin-left: 84px;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0px;
    padding-bottom: 40px;
    margin-left: 15px;
    margin-right: 15px;
    @include breakpoint (xxlarge only) {
      margin-left: 84px;
      margin-right: 245px;
    }
    @include breakpoint (xlarge only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (large only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (medium only) {
      margin-left: 84px;
      margin-right: 0px;
    }
  }
}

.supply-hero-button {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
  padding-bottom: 60px;
  margin-left: 15px;
  @include breakpoint (medium) {
    margin-left: 84px;
    padding-bottom: 30px;
  }
  a {
    color: white;
    border: 1px solid #fff;
    padding: 10px 28px;
    &:hover {
      font-weight: bold;
    }
  }
}

.supply-hero-image {
  display: none;
  @include breakpoint (medium only) {
    margin-top: -122px;
    z-index: 2;
    position: relative;
    width: 100%;
    float: left;
    display: inline-block;
    margin-bottom: -136px;
  }
  @include breakpoint (large only) {
    margin-top: -137px;
    margin-bottom: -103px;
    z-index: 2;
    position: relative;
    width: 100%;
    float: left;
    display: inline-block;
  }
  @include breakpoint (xlarge only) {
    margin-top: -122px;
    margin-bottom: -151px;
    z-index: 2;
    position: relative;
    width: 100%;
    float: left;
    display: inline-block;
  }
  @include breakpoint (xxlarge only) {
    margin-top: -159px;
    margin-bottom: -105px;
    z-index: 2;
    position: relative;
    width: 100%;
    float: left;
    display: inline-block;
  }
}

.supply-hero-image-2 {
  @include breakpoint (medium) {
    display: none;
  }
}

//JOIN SECTION //

.join-hero-text {
  background-color: #7c90a0;
  color: white;
  z-index: 1;
  position: relative;
  float: left;
  padding-top: 40px;
  text-align: center;
  width: 100%;
  padding-bottom: 60px;
  @include breakpoint (xxlarge only) {
    width: 80%;
    left: 152px;
    padding-top: 88px;
    text-align: left;
  }
  @include breakpoint (xlarge only) {
    width: 80%;
    left: 131px;
    padding-top: 162px;
    text-align: left;
  }
  @include breakpoint (large only) {
    width: 80%;
    left: 105px;
    padding-top: 122px;
    text-align: left;
  }
  @include breakpoint (medium only) {
    padding-right: 35px;
    padding-top: 155px;
    text-align: left;
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin-left: 15px;
    @include breakpoint (medium) {
      float: right;
      margin-right: 15px;
    }
    @include breakpoint (large) {
      margin-right: 84px;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0px;
    padding-bottom: 40px;
    margin-right: 15px;
    margin-left: 15px;
    @include breakpoint (large) {
      margin-left: 114px;
      margin-right: 84px;
      float: right;
    }
    @include breakpoint (medium only) {
      padding-left: 85px;
      float: right;
    }
    @include breakpoint (xlarge) {
      margin-left: 322px;
      margin-right: 84px;
      float: right;
    }
  }
}

.join-hero-button {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2px;
  padding-bottom: 45px;
  margin-left: 15px;
  @include breakpoint (medium) {
    float: right;
    margin-right: 15px;
  }
  @include breakpoint (large) {
    margin-right: 84px;
  }
  a {
    color: white;
    border: 1px solid #fff;
    padding: 10px 28px;
    &:hover {
      font-weight: bold;
    }
  }
}

// STATS SECTION //

.stats-header {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 50px;
  @include breakpoint(medium) {
    padding-top: 100px;
  }
  h3 {
    margin-bottom: -7px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    color: #777a7a;
  }
  h2 {
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    color: #777a7a;
  }
}

.stats-wrapper {
  position: relative;
  width: 100%;
  float: left;
  padding-bottom: 40px;
}

.delivery-stats, .product-stats, .employee-stats {
  text-align: center;
  i {
    color: #9fc3bf;
    font-size: 150px;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    letter-spacing: 3px;
    padding-top: 10px;
    color: #777a7a;
  }
  p {
    font-size: 19px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 25px;
    color: #777a7a;
  }
}

.product-stats {
  @include breakpoint (medium) {
    padding-top: 8px;
  }
}

.employee-stats {
  padding-bottom: 50px;
  @include breakpoint (medium) {
    padding-top: 28px;
    padding-left: 30px;
    padding-bottom: 0px;
  }
}

.delivery-stats {
  @include breakpoint (medium) {
    padding-right: 30px;
  }
}

// SOCIAL SECTION //

.social-header {
  text-align: center;
  color: #777a7a;
  position: relative;
  float: left;
  width: 100%;
  margin-top: 85px;
  @include breakpoint (medium) {
    margin-top: 140px;
  }
  @include breakpoint (large) {
    margin-top: 120px;
  }
  @include breakpoint (xlarge) {
    margin-top: 112px;
  }
  h3 {
    margin-bottom: -7px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
  }
  h2 {
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    font-weight: bold;
  }
  p {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    @include breakpoint (medium) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include breakpoint (large only) {
      padding-right: 150px;
      padding-left: 150px;
      font-size: 18px;
    }
    @include breakpoint (xlarge only) {
      padding-right: 180px;
      padding-left: 180px;
      font-size: 21px;
    }
    @include breakpoint (xxlarge only) {
      padding-left: 200px;
      padding-right: 200px;
      font-size: 21px;
    }
  }
}


.home-social-icons-wrapper {
  text-align: center;
  padding-bottom: 90px;
  @include breakpoint(medium) {
    padding-bottom: 145px;
  }
}
.home-social-icons {
  display: inline;
}
.home-facebook-icon, .home-pinterest-icon, .home-linkedin-icon {
  display: inline;
  a {
    font-size: 55px;
    padding: 11px;
    color: #9fc3bf;
    transition: color 0.2s ease-in-out;
    @include breakpoint(medium){
      font-size: 68px;
      padding: 35px;
    }
    &:hover {
      color: #257d73;
    }
  }
}
// CONTACT SECTION //

.contact-section-wrapper {
  text-align: center;
  color: #777a7a;
  position: relative;
  float: left;
  width: 100%;
  padding-top: 80px;
  @include breakpoint(medium) {
    padding-top: 145px;
  }
  h3 {
    margin-bottom: -7px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
  }
  h2 {
    font-size: 48px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    font-weight: bold;
  }
  p {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    @include breakpoint (medium) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @include breakpoint (large only) {
      padding-right: 150px;
      padding-left: 150px;
    }
    @include breakpoint (xlarge only) {
      padding-right: 180px;
      padding-left: 180px;
    }
    @include breakpoint (xxlarge only) {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}

.form-button {
  text-align: center;
  padding-bottom: 20px;
  button {
    background-color: #257d73;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    color: white;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #257d73;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: #f8f8f8;
      color: #257d73;
      border: 2px solid #257d73;
      font-weight: bold;
    }
  }
}


// FOOTER SECTION //

.footer-wrapper {
  float: left;
  padding-top: 60px;
  @include breakpoint(medium) {
    padding-top: 130px;
  }
}

// UPPER FOOTER //

.upper-footer-wrapper {
  transform: skewY(4deg);
  background-color: #257d73;
  width: 100%;
  position: relative;
  height: 860px;
  margin-bottom: -50px;
  float: left;
  @include breakpoint (medium) {
    height: 530px;
  }
  @include breakpoint (large) {
    height: 515px;
  }
}

.upper-footer {
  color: white;
  padding-top: 40px;
  padding-bottom: 10px;
  position: relative;
  float: left;
  width: 100%;
  margin-top: 68px;
  transform: skewY(-4deg);

  h6 {
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    margin-left: 80px;
    margin-bottom: 21px;
  }
  p {
    margin-left: 80px;
    font-size: 15px;
    margin-bottom: 0px;
  }
}

.footer-collaboration, .footer-about, .footer-contact, .footer-ourbrands {
  margin-top: 30px;
  @include breakpoint (medium) {
    margin-top: 0px;
  }
}

.footer-contact {
  a {
    color: white;
  }
}

.footer-ourbrands {
  ul {
    list-style-type: none;
    margin-left: 80px;
    line-height: 28px;
    li a {
      color: white;
    }
  }
}

.footer-about {
  ul {
    list-style-type: none;
  }
  a {
    margin-left: 62px;
    color: white;
  }
}

.footer-zogz-link {
  float: right;
  padding-top: 30px;
  margin-right: 35px;
  @include breakpoint (large only) {
    padding-top: 0px;
  }
  a {
    color: #11c6dd;
  }
}

// LOWER FOOTER //
.lower-footer {
  background-color: #555555;
  position: relative;
  float: left;
  width: 100%;
}

.lower-footer-text {
  color: #bcbcbc;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-left: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  @include breakpoint (medium) {
    margin-left: 80px;
  }
  p {
    margin-bottom: 0px;
    line-height: 20px;
  }
}

.footer-social-icons {
  font-size: 28px;
  padding-bottom: 20px;
  padding-top: 30px;
  text-align: center;
  @include breakpoint (medium) {
    float: right;
    margin-right: 35px;
  }
  a {
    color: #bcbcbc;
    transition: color 0.5s ease;
    &:hover {
      color: #257d73;
    }
  }
}

.footer-facebook-icon {
  display: inline-block;
  padding-left: 17px;
}

.footer-linkedin-icon {
  display: inline-block;
  padding-left: 17px;
}

.footer-pinterest-icon {
  display: inline-block;
  padding-left: 17px;
}


// RECRUITMENT PAGE //

.recruitment-hero-image, .supply-hero-image2 {
  position: relative;
  float: left;
  width: 100%;
  z-index: 2;
  margin-top: 78px;
  @include breakpoint(medium) {
    margin-top: 115px;
  }
  @include breakpoint(large) {
    margin-top: 100px;
  }
  @include breakpoint(xlarge) {
    margin-top: 21px;
  }
    img {
      z-index: 2;
      position: relative;
      float: left;
    }
}

.recruitment-hero-text, .supply-hero-text2 {
  background-color: #257d73;
  color: white;
  z-index: 1;
  position: relative;
  float: left;
  padding-top: 212px;
  margin-top: -176px;
  padding-right: 10px;
  @include breakpoint (xxlarge only) {
    width: 80%;
    margin-top: -249px;
    left: 120px;
  }
  @include breakpoint (xlarge only) {
    width: 80%;
    margin-top: -246px;
    left: 131px;
  }
  @include breakpoint (large only) {
    width: 80%;
    margin-top: -232px;
    left: 105px;
  }
  @include breakpoint (medium only) {
    margin-top: -218px;
    padding-right: 35px;
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin-left: 15px;
    @include breakpoint (medium) {
      margin-left: 84px;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0px;
    padding-bottom: 60px;
    margin-left: 15px;
    @include breakpoint (xxlarge only) {
      margin-left: 84px;
      margin-right: 245px;
    }
    @include breakpoint (xlarge only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (large only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (medium only) {
      margin-left: 84px;
      padding-bottom: 60px;
    }
  }
}

.recruitment-reasons-section {
  text-align: center;
}

.recruitment-reasons {
  margin-top: 100px;
  @include breakpoint(large) {
    margin-top: 150px;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
    color: #777a7a;
    letter-spacing: 2px;
    padding-top: 26px;
    @include breakpoint(medium only) {
      font-size: 17px;
    }
    @include breakpoint(large only) {
      font-size: 19px;
    }
  }
  p {
    color: #777a7a;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @include breakpoint(medium only) {
      font-size: 13px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.positions-header-wrapper {
  text-align: center;
  margin-bottom: 100px;
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    color: #257d73;
    font-weight: bold;
    font-size: 38px;
    padding-top: 80px;
    @include breakpoint(medium only) {
      padding-top: 100px;
    }
    @include breakpoint(large) {
      font-size: 48px;
      padding-top: 150px;
    }
  }
  h4 {
    color: #777a7a;
    margin-top: 20px;
    @include breakpoint(medium) {
      margin-left: 40px;
      margin-right: 40px;
    }
    @include breakpoint(large) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
}

.position-1, .position-2, .position-3, .position-4 {
  text-align: center;
  margin-bottom: 50px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 20px;
  color: #777a7a;
  background-color: #dbe9e8;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #257d73;
    color: white;
  }
  @include breakpoint(medium) {
    margin-left: 50px;
    margin-right: 50px;
  }
  h3 {
    font-family: 'Montserrat', sans-serif;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  p {
    font-size: 13px;
  }
}

// SUPPLY PAGE //


.supply-hero-image2 {
  position: relative;
  float: left;
  width: 100%;
  z-index: 2;
  margin-top: 78px;
  @include breakpoint(medium) {
    margin-top: 115px;
  }
  @include breakpoint(large) {
    margin-top: 100px;
  }
  @include breakpoint(xlarge) {
    margin-top: 21px;
  }
  img {
    z-index: 2;
    position: relative;
    float: left;
  }
}

.supply-hero-text2 {
  background-color: #257d73;
  color: white;
  z-index: 1;
  position: relative;
  float: left;
  padding-top: 165px;
  margin-top: -176px;
  padding-right: 10px;
  @include breakpoint (xxlarge only) {
    width: 80%;
    margin-top: -337px;
    left: 120px;
  }
  @include breakpoint (xlarge only) {
    width: 80%;
    margin-top: -309px;
    left: 131px;
  }
  @include breakpoint (large only) {
    width: 80%;
    margin-top: -252px;
    left: 105px;
  }
  @include breakpoint (medium only) {
    margin-top: -238px;
    padding-right: 35px;
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin-left: 15px;
    @include breakpoint (medium) {
      margin-left: 84px;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0px;
    padding-bottom: 60px;
    margin-left: 15px;
    @include breakpoint (xxlarge only) {
      margin-left: 84px;
      margin-right: 245px;
    }
    @include breakpoint (xlarge only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (large only) {
      margin-right: 90px;
      margin-left: 84px;
    }
    @include breakpoint (medium only) {
      margin-left: 84px;
      padding-bottom: 60px;
    }
  }
}

.supply-reason-header {
  text-align: center;
  margin-top: 100px;
  h2 {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    color: #257d73;
    font-weight: bold;
    @include breakpoint(medium) {
      font-size: 38px;
    }
  }
  h4 {
    color: #777a7a;
    margin-top: 20px;
    @include breakpoint(medium) {
      margin-left: 40px;
      margin-right: 40px;
    }
    @include breakpoint(large) {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
}

.arrow-down {
  margin-top: 40px;
  @include breakpoint(medium) {
    margin-top: 100px;
  }
}

.supply-reason-section {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  @include breakpoint(medium) {
    margin-top: 30px;
  }
  @include breakpoint(large) {
    margin-bottom: 50px;
  }
}

.supply-reason {
  h1 {
    font-family: 'Montserrat', sans-serif;
    color: #dbe9e8;
    font-size: 300px;
    font-weight: bold;
  }
  h4 {
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 15px;
    margin-top: -270px;
    margin-right: 30px;
    margin-left: 20px;
    @include breakpoint(medium) {
      margin-left: 75px;
    }
  }
  p {
    color: #555555;
    margin-right: 30px;
    margin-left: 20px;
    @include breakpoint(medium) {
      margin-right: 30px;
      margin-left: 75px;
    }
    @include breakpoint(large) {
      margin-right: 100px;
    }
  }
}


// HELP DOCS //

.main-content {
  padding-top: 110px;
  color: #777a7a;
  @include breakpoint(medium) {
    padding-top: 150px;
  }
  a {
    color: #257d73;
  }
  h2 {
    font-size: 1.5rem;
    color: #257d73;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.main-content {
  .background--white {
    background-color: white;
    padding-top: 20px;
    padding-left: 10px;
  }
}

#general-enquiry {
  background-color: #eaeaea;
  padding-top: 10px;
  padding-right: 15px;
}

ul.no-bullet {
  box-shadow: 9px 10px 8px 1px #a9a7a7;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  li {
    a:hover {
      text-decoration: underline;
    }
  }
}

.button.primary {
  background-color: #257d73;
  &:hover {
    background-color: #19504a;
  }
  &:focus {
    background-color: #19504a;
  }
}

.reveal {
  #email-modal {
    top: 100px !important;
  }
}
