
.mod-normal-form {
	input[type="text"], input[type="email"], textarea {
		margin-left: 10px;
	}
	h6 {
		font-family: 'Montserrat', sans-serif;
		color: #257d73;
		font-size: 16px;
	}
}

.main-content {
	input[type="submit"] {
		background-color: #257d73;
		&:hover {
			background-color: #dbe9e8;
			color: #777a7a;
		}
	}
	input[type="text"], input[type="email"], textarea {
		background-color: white;
		color: #257d73;
		margin-left: 10px;
		&:focus {
			border: 1px solid #257d73;
		}
	}
}
